<template>
    <section class="cards-container">
        <table class="table mb-0 table-sm without-bordered">
            <tr>
                <td>
                    <label>{{ parseNumberToMoney(batch.total_value) }} <span class="badge">Faturado</span></label>
                </td>
                <td>
                    <label>{{ batch.guides_count }} <span class="badge">Qtde. de guias</span></label>
                </td>
                <td>
                    <label class="text-green">{{ [ SusBatch.BATCH_STATUS_TO_SENT, SusBatch.BATCH_STATUS_SENT, SusBatch.BATCH_STATUS_PAYED ].includes(batch.status) ? parseNumberToMoney(batch.total_value) : parseNumberToMoney(0) }}<span class="badge">A receber</span></label>
                </td>
                <td>
                    <label class="text-green">{{ batch.status === SusBatch.BATCH_STATUS_FINISHED && batch.payment_date ? parseNumberToMoney(batch.total_value) : parseNumberToMoney(0) }} <span class="badge">Pago</span></label>
                </td>
                <td>
                    <label class="text-red">{{ batch.status === SusBatch.BATCH_STATUS_GLOSSED || ( batch.status === SusBatch.BATCH_STATUS_FINISHED && !batch.payment_date ) ? parseNumberToMoney(batch.total_value) : parseNumberToMoney(0) }} <span class="badge">Glosado</span></label>
                </td>
                <td class="d-flex inline vertical-align-middle">
                    <button class="button-gray" @click="$emit('changeShow')"
                        v-b-tooltip.hover :title="'Visualizar guias'"
                    >
                        <ChevronDown class="chevron-icon" :class="{'show' : !!show}"/>
                    </button>
                    
                    <Clip
                        @click="openDocumentListModal(batch)"
                        class="icon-gray ml-2 mt-2"
                        v-b-tooltip.hover 
                        :title="'Arquivos anexados'"
                    />
                </td>
            </tr>
        </table>
    </section>
</template>
<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { SusBatch } from '@/modules/sus/manageGuides/utils/statuses'
export default {
    components: {
        Clip: () => import('@/assets/icons/clip.svg'),
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    },
    props: {
        batch: Object,
        show: Boolean,
        openDocumentListModal: Function,
    },  
    data() {
        return {
            SusBatch,
        }
    },
    methods: {
        parseNumberToMoney,
    }
}
</script>
<style lang="scss" scoped>
    .cards-container {
        h4 {
            color: #0C1D59; 
            font-weight: 600;
            font-size: 18px;
        }
        .cards {
            width: 100%;
            gap: 10px;
            display: inline-flex;
            justify-content: space-between;
        }
    }
    .skeleton {
        width: 200px;
        height: 100px;
    }
    .without-bordered{
        background: #FFF;
        tr{
            td{
                border: none;
                background-color: #FFF !important;
                label{
                    width: 95%;
                    border-radius: 8px;
                    background: var(--surface-secondary, #F8F8FD);
                    padding: 10px;
                    .badge{
                        float: right;
                        color: var(--border-hover, #A4B1DF);
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
                .icon-gray {
                    cursor: pointer;
                    stroke: var(--neutral-100);
                    height: 24px;
                    width: 24px;

                    path{
                        stroke: var(--border-hover, #A4B1DF)
                    }
                }

                &.vertical-align-middle{
                    vertical-align: middle;
                }
            }
        }
    }
    .text-red{
        color: red !important;
    }
    .text-green{
        color: green !important;
    }
    .button-gray{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background-color: #eef1fc;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: background-color 0.3s;
      }
      
      .button-gray:hover {
        background-color: #d9dff7;
      }
      
      .chevron-icon {
        stroke: gray;
        transition: transform 0.3s ease;
        height: 20px;
        width: 20px;
      }
      
      .chevron-icon .show {
        transform: rotate(180deg);
      }
</style>